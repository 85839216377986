let SocialMediaLinksData = [{
    "url": "https://www.facebook.com/xcofin",
    "classname": "fa fa-facebook-f xc-footer-social-link"
}, {
    "url": "https://www.instagram.com/xcofin_investments/",
    "classname": "fa fa-instagram xc-footer-social-link"
}, {
    "url": "https://www.youtube.com/@xcofin_investments",
    "classname": "fa fa-youtube-play xc-footer-social-link"
}, {
    "url": "https://x.com/xcofin_tm",
    "classname": "fa fa-twitter xc-footer-social-link"
}, {
    "url": "mailto:info@xcolon.org",
    "classname": "fa fa-google xc-footer-social-link"
}, {
    "url": "https://github.com/XCOFIN-INVESTMENTS",
    "classname": "fa fa-github xc-footer-social-link"
}, {
    "url": "https://t.me/xcofin",
    "classname": "fa fa-telegram xc-footer-social-link"
}, {
    "url": "https://www.whatsapp.com/channel/0029Vajck7IFi8xmDc18G70q",
    "classname": "fa fa-whatsapp xc-footer-social-link"
}]

export default SocialMediaLinksData