import React, { Component } from 'react';
import GetImageURL from '../../../Utils/GetImageURL';

class HomePageComponent extends Component {
    render() {
        return (
            <div>
                <br />
                <div className="card text-center">
                    <div className="card-body xc-card-body">
                        <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Head")} alt="Categories" />
                        <br/>
                        <h1 className="card-text">𝗜𝗻𝘃𝗲𝘀𝘁𝗺𝗲𝗻𝘁𝘀 𝗦𝗲𝗿𝘃𝗶𝗰𝗲𝘀 𝗔𝗻𝗱 𝗙𝗶𝗻𝗮𝗻𝗰𝗶𝗮𝗹 𝗔𝘀𝘀𝗲𝘁𝘀 𝗠𝗮𝗻𝗮𝗴𝗲𝗺𝗲𝗻𝘁</h1>
                        <br />
                        <h4>𝗜𝗻𝘃𝗲𝘀𝘁 𝗦𝗺𝗮𝗿𝘁, 𝗟𝗶𝘃𝗲 𝗕𝗲𝘁𝘁𝗲𝗿</h4>
                        <h4>𝗠𝗮𝘅𝗶𝗺𝗶𝘇𝗲 𝗥𝗲𝘁𝘂𝗿𝗻𝘀, 𝗠𝗶𝗻𝗶𝗺𝗶𝘇𝗲 𝗥𝗶𝘀𝗸𝘀</h4>
                        <br/>
                        <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Works")} alt="Categories" />
                    </div>
                </div>
                <br/><br/>
                <div className="card text-center mx-auto p-2 border border-success xc-home-tech-section">
                    <div className="card-header"><h4><strong>𝗖𝗔𝗣 𝗥𝗜𝗦𝗘 𝗜𝗡𝗩𝗘𝗦𝗧𝗠𝗘𝗡𝗧𝗦 𝗣𝗟𝗔𝗡𝗦</strong></h4></div>
                    <div className="card-body">
                        <div className="container text-center">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="card xc-category-card">
                                        <div className="card-body text-center">
                                            <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Returns")} alt="Categories" />
                                            <div className="card text-center mx-auto p-2 border border-success">
                                                <div className="card-body">
                                                    <br/>
                                                    <h5 className="card-title"><strong>BASIC PLAN</strong></h5>
                                                    <br/>
                                                    <p className="card-text">Invest Range : ₹20,000 - ₹1,80,000</p>
                                                    <p className="card-text">Invest As Multiples of ₹20,000</p>
                                                    <p className="card-text">First 3 Month Hold Without Profit</p>
                                                    <p className="card-text">Profit : 5% of investment per Month</p>
                                                    <p className="card-text">Profit Available After 3 Month Hold</p>
                                                    <p className="card-text">Maximum Duration : 5 Years</p>
                                                    <p className="card-text">You Can Close After Hold And Before Maximum Duration</p>
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="card text-center mx-auto p-2 border border-success">
                                                <div className="card-body">
                                                    <br/>
                                                    <h5 className="card-title"><strong>STANDARD PLAN</strong></h5>
                                                    <br/>
                                                    <p className="card-text">Invest Range : ₹2,00,000 - ₹4,75,000</p>
                                                    <p className="card-text">Invest As Multiples of ₹25,000</p>
                                                    <p className="card-text">First 3 Month Hold Without Profit</p>
                                                    <p className="card-text">Profit : 10% of investment per Month</p>
                                                    <p className="card-text">Profit Available After 3 Month Hold</p>
                                                    <p className="card-text">Maximum Duration : 5 Years</p>
                                                    <p className="card-text">You Can Close After Hold And Before Maximum Duration</p>
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="card text-center mx-auto p-2 border border-success">
                                                <div className="card-body">
                                                    <br/>
                                                    <h5 className="card-title"><strong>ADVANCED PLAN</strong></h5>
                                                    <br/>
                                                    <p className="card-text">Invest Range : ₹5,00,000 - ₹10,00,000</p>
                                                    <p className="card-text">Invest As Multiples of ₹50,000</p>
                                                    <p className="card-text">First 3 Month Hold Without Profit</p>
                                                    <p className="card-text">Profit : 20% of investment per Month</p>
                                                    <p className="card-text">Profit Available After 3 Month Hold</p>
                                                    <p className="card-text">Maximum Duration : 5 Years</p>
                                                    <p className="card-text">You Can Close After Hold And Before Maximum Duration</p>
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="card text-center mx-auto p-2 border border-success">
                                                <div className="card-body">
                                                    <br/>
                                                    <h5 className="card-title"><strong>ULTIMATE PLAN</strong></h5>
                                                    <br/>
                                                    <p className="card-text">Invest Range : Above ₹10,00,000</p>
                                                    <p className="card-text">Invest As Multiples of ₹1,00,000</p>
                                                    <p className="card-text">First 3 Month Hold Without Profit</p>
                                                    <p className="card-text">Profit : 25% of investment per Month</p>
                                                    <p className="card-text">Profit Available After 3 Month Hold</p>
                                                    <p className="card-text">Maximum Duration : 5 Years</p>
                                                    <p className="card-text">You Can Close After Hold And Before Maximum Duration</p>
                                                </div>
                                            </div>
                                            <br/>
                                            <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Maturity")} alt="Categories" />
                                            <h4 className="card-title"><strong>PLAN HIGHLIGHTS</strong></h4>
                                            <br/>
                                            <h5 className="card-title">Hold Duration</h5>
                                            <h5 className="card-title"><strong>First 3 Months Without Profit</strong></h5>
                                            <br/>
                                            <h5 className="card-title">Profit Transfer</h5>
                                            <h5 className="card-title"><strong>At Issued Date In Every Month After Hold Duration</strong></h5>
                                            <br/>
                                            <h5 className="card-title">Maximum Duration</h5>
                                            <h5 className="card-title"><strong>5 Years After First Investment</strong></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/><hr/><br/>
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="card xc-category-card">
                                        <div className="card-body text-center">
                                            <h4><strong>CHOOSE AND DEAL WITH US</strong></h4>
                                            <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Deal")} alt="Categories" />
                                            <h5 className="card-title">Choose Your Plan</h5>
                                            <h5 className="card-title">Contact Directly With Us</h5>
                                            <br/>
                                            <h5 className="card-title">We Will Ask You</h5>
                                            <h6>Your Details : Name, Contact Number, Contact Email ID, Aadhaar, PAN, Bank Details (Name, Account Number, IFSC Code)</h6>
                                            <h6>Nominee Details : Name, Contact Number, Contact Email ID, Address Proof.</h6>
                                            <br/>
                                            <p className="card-text">Contact Us</p>
                                            <h5 className="card-title">+91 9072307234</h5>
                                            <h5 className="card-title">info@xcolon.org</h5>
                                            <a href="mailto:info@xcolon.org" className="btn btn-primary">Send Mail</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/><hr/><br/>
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="card xc-category-card">
                                        <div className="card-body text-center">
                                            <h4><strong>YOUR SECURITIES</strong></h4>
                                            <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Agreement")} alt="Categories" />
                                            <h5 className="card-title">Investment Agreement</h5>
                                            <p>Both Parties Signed Authorized Agreement</p>
                                            <h5 className="card-title">Investment Bond Certificate</h5>
                                            <p>Company Certified Proof Of Investment</p>
                                            <br/>
                                            <p className="card-text">Sign The Agreement. Check Agreement And Bond Certificate. Keep The Documents.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/><hr/><br/>
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="card xc-category-card">
                                        <div className="card-body text-center">
                                            <h4><strong>HOW WE MAKES PROFIT</strong></h4>
                                            <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Investment")} alt="Categories" />
                                            <h5 className="card-title">Equity Investments</h5>
                                            <h5 className="card-title">Dividend Income</h5>
                                            <h5 className="card-title">Derivatives - Options & Futures</h5>
                                            <h5 className="card-title">Alternative Investments</h5>
                                            <br/>
                                            <p className="card-text">Managing Your Money In Secured Ways.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/><hr/><br/>
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="card xc-category-card">
                                        <div className="card-body text-center">
                                            <h4><strong>PROFIT AND ROI</strong></h4>
                                            <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Profit")} alt="Categories" />
                                            <h6 className="card-text">Fixed Profit Monthly Returns After Hold Duration.</h6>
                                            <h6 className="card-text">No Market Risks. Fixed Profit Return.</h6>
                                            <h6 className="card-text">Transfers On Issued Date In Every Month After Hold Duration.</h6>
                                            <h6 className="card-text">Before Maximum Duration And After Hold Duration, You Can Withdraw Your Investments.</h6>
                                            <h6 className="card-text">Expires And Return Investments Automatically On Maximum Duration.</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/><hr/><br/>
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="card xc-category-card">
                                        <div className="card-body text-center">
                                            <h4><strong>DOCUMENTATION AND REPORT</strong></h4>
                                            <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Report")} alt="Categories" />
                                            <p>We Will Prepare & Share Following Documents</p>
                                            <h5 className="card-title">Investment Agreement</h5>
                                            <h5 className="card-title">Investment Bond Certificate</h5>
                                            <h5 className="card-title">Investment Reports</h5>
                                            <h5 className="card-title">Clousure Documents</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/><hr/><br/>
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="card xc-category-card">
                                        <div className="card-body text-center">
                                            <h4><strong>Financial Freedom For Eveyone</strong></h4>
                                            <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Satisfied")} alt="Categories" />
                                            <h4><strong>Customer Satisfaction</strong></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className="card text-center">
                    <div className="card-body xc-card-body">
                        <h4>𝗜𝗻𝘃𝗲𝘀𝘁𝘀 𝘄𝗶𝘁𝗵 𝗖𝗼𝗻𝗳𝗶𝗱𝗲𝗻𝗰𝗲, 𝗠𝗮𝗻𝗮𝗴𝗶𝗻𝗴 𝘄𝗶𝘁𝗵 𝗣𝗿𝗲𝗰𝗶𝘀𝗶𝗼𝗻</h4>
                        <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Tagline")} alt="Categories" />
                        <p className="card-text">We providing comprehensive financial management solutions, including investment, assets management, deposits, payments, and credit systems. Our innovative services streamline financial operations, enhance asset growth, and ensure secure transactions, empowering clients to achieve their financial goals with confidence and efficiency.</p>
                        <br/><br/>
                        <p className="card-text">Copyright © XCOFIN INVESTMENTS PRIVATE LIMITED. All rights reserved.</p>
                        <br/>
                    </div>
                </div>
            </div>
        )
    }
}
export default HomePageComponent