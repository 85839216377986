import React, { Component } from 'react';
import SocialMediaLinksData from '../../../../Model/Links/SocialMediaLinks';
// import ContactModal from '../../../../Shared/Modals/Contact';

class ContactPageComponent extends Component {
    render() {
        return (
            <div className="container text-center">
                <br/>
                <div className="row justify-content-md-center">
                    <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                        <h2 className="mb-4 display-5 text-center"><strong>Contact</strong></h2>
                        <br/>
                        <p className="text-secondary mb-5 text-center">Contact us from below</p>
                    </div>
                    <br/>
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed xc-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                Email Us
                            </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="card text-center">
                                    <div className="card-body">
                                        <br/>
                                        <p className="card-text"><strong>info@xcolon.org</strong></p>
                                        <a href="mailto:info@xcolon.org" className="btn btn-primary">Send Mail</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                            <button className="accordion-button collapsed xc-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                Community
                            </button>
                            </h2>
                            <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="card text-center">
                                    <div className="card-body xc-contact-social-links">
                                        <br/>
                                        <section className="mb-4">
                                            {
                                                SocialMediaLinksData.map((item, index) => {
                                                    return <a data-mdb-ripple-init className="btn btn-link btn-floating btn-lg text-body m-1" role="button" data-mdb-ripple-color="dark" key={index} href={item.url} target="_blank" rel="noopener noreferrer"><i className={item.classname}></i></a>
                                                })
                                            }
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactPageComponent